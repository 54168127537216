
import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';

import Isvg from 'react-inlinesvg';
import { Editor } from '@tinymce/tinymce-react';

class Html extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    render() {
        return (
            <div className="input-wrap">
                <label>{this.props.translate(this.props.label)}</label>
                <Editor
                    apiKey="lweh8ghdiwccyg0ogbblrkzamnybbrxoay2b7ontj3tusq7c"
                    init={{ plugins: 'link table', height: 300 }}
                    value={this.props.multilang ? (this.props.value && this.props.value[this.props.lang]) ? this.props.value[this.props.lang] : '' : this.props.value}
                    onEditorChange={(val) => {

                        if (this.props.multilang) {
                            let value = this.props.value;
                            if (!value) {
                                value = {};
                            }
                            value[this.props.lang] = val;
    
                            this.props.onChange(value);
                        } else {
    
                            this.props.onChange(val);
                        }
                        this.forceUpdate();
    
    
                    }}
                />
            </div>
        );
    }
}

export default Html;